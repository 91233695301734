<template>
  <div class="mb-2">
    <template v-if="getLogs.length === 0 || isLoading === true">
      <div
        class="
          flex
          items-center
          justify-center
          py-5
          bg-white
          rounded-md
          shadow
          h-96
        "
      >
        <div
          class="text-xl font-medium"
          v-text="isLoading ? 'Loading..' : 'No logs available'"
        />
        <AppButton :is-loading="isLoading" variant="transparent" text="" />
      </div>
    </template>

    <template v-else>
      <div class="full-mode">
        <section class="fst-wrapper">
          <table class="fst">
            <thead class="fst-head">
              <tr class="fst-head-row">
                <th
                  v-for="header in getHeaders"
                  :key="header.label"
                  :width="header.width"
                  class="fst-head-row-item event-col-header"
                  :class="{ 'ta-center-imp': header.center }"
                >
                  {{ header.label }}
                </th>
              </tr>
            </thead>
            <template v-for="groupedLogs in getLogs">
              <TimelineLogItem
                v-for="(log, logIndex) in groupedLogs"
                :key="`log-item-${logIndex}`"
                :variant="getLogItemVariant(log)"
                :time="getLogTime(log)"
                :date="getLogDate(log)"
                :time-ago="getLogTimeAgo(log)"
                :connected-with="getConnector(log, logIndex, groupedLogs)"
                :oto-icon="log.action_type"
                :timelineType="timelineType"
                :response-code="getLogResponseCode(log)"
                :coords="log.location_coord"
                @click-map="onClickMap"
                :user-balance="getUserBalance(log)"
                :user-balance-currency="getUserBalanceCurrency(log)"
                :tripId="log.trip ? log.trip : ''"
                :power-level="getPowerLevel(log)"
                :actor="getActor(log)"
                :remarks="getRemarks(log)"
                :pointsHistory="getPointsHistory(log)"
                :isPointsShow="isPointsShow"
              >
                <!--
                :icon-src="getLogIconSrc(log)"
                :icon-class="getLogIconClass(log)"
                -->
                <template #visible>
                  <TimelineLogLayerVisible
                    :log="log"
                    :html-title="getHtmlTitle(log)"
                    :coords="log.location_coord"
                    :power-level="getPowerLevel(log)"
                    :response-code="getLogResponseCode(log)"
                    :info-column-preset="infoColumnPreset"
                    @click-map="onClickMap"
                  />
                </template>

                <template #detailsContent>
                  <TimelineLogLayerDetails
                    :key="`log-item-parent-${logIndex}`"
                    :title="log.action_type + ' request sent'"
                    :time="getLogTime(log)"
                    :request-trace="getLogRequestTrace(log)"
                    :iot-data="getLogIotData(log)"
                    :response-code="getLogResponseCode(log)"
                    :variant="`secondary`"
                    :context="getContextData(log)"
                  >
                  </TimelineLogLayerDetails>

                  <TimelineLogLayerDetails
                    v-for="(childLog, childIndex) in log.children"
                    :key="`log-item-child-${childIndex}`"
                    :title="getLogTitle(childLog)"
                    :time="getLogTime(childLog)"
                    :iot-data="getLogIotData(childLog)"
                    :request-trace="getLogRequestTrace(childLog)"
                    :response-code="getLogResponseCode(childLog)"
                    :variant="`secondary`"
                    :context="getContextData(childLog)"
                  >
                  </TimelineLogLayerDetails>
                </template>
              </TimelineLogItem>
            </template>
          </table>
        </section>
      </div>
      <div class="responsive-mode">
        <section class="fst-wrapper">
          <table class="fst">
            <thead class="fst-head">
              <tr class="fst-head-row">
                <th
                  v-for="header in getResponsiveHeaders"
                  :key="header.label"
                  :width="header.width"
                  class="fst-head-row-item event-col-header"
                  :class="{ 'ta-center-imp': header.center }"
                >
                  {{ header.label }}
                </th>
              </tr>
            </thead>
            <template v-for="groupedLogs in getLogs">
              <TimelineLogItem
                v-for="(log, logIndex) in groupedLogs"
                :key="`log-item-${logIndex}`"
                :variant="getLogItemVariant(log)"
                :logIndex="logIndex"
                :time="getLogTime(log)"
                :date="getLogDate(log)"
                :time-ago="getLogTimeAgo(log)"
                :connected-with="getConnector(log, logIndex, groupedLogs)"
                :oto-icon="log.action_type"
                :timelineType="timelineType"
                :response-code="getLogResponseCode(log)"
                :coords="log.location_coord"
                @click-map="onClickMap"
                :user-balance="getUserBalance(log)"
                :user-balance-currency="getUserBalanceCurrency(log)"
                :tripId="log.trip ? log.trip : ''"
                :power-level="getPowerLevel(log)"
                :actor="getActor(log)"
                :remarks="getRemarks(log)"
                :pointsHistory="getPointsHistory(log)"
                :isPointsShow="isPointsShow"
              >
                <!--
                :icon-src="getLogIconSrc(log)"
                :icon-class="getLogIconClass(log)"
                -->
                <template #visible>
                  <TimelineLogLayerVisible
                    :log="log"
                    :html-title="getHtmlTitle(log)"
                    :coords="log.location_coord"
                    :power-level="getPowerLevel(log)"
                    :response-code="getLogResponseCode(log)"
                    :info-column-preset="infoColumnPreset"
                    @click-map="onClickMap"
                  />
                </template>

                <template #detailsContent>
                  <TimelineLogLayerDetails
                    :key="`log-item-parent-${logIndex}`"
                    :title="log.action_type + ' request sent'"
                    :time="getLogTime(log)"
                    :request-trace="getLogRequestTrace(log)"
                    :iot-data="getLogIotData(log)"
                    :response-code="getLogResponseCode(log)"
                    :variant="`secondary`"
                    :context="getContextData(log)"
                  >
                  </TimelineLogLayerDetails>

                  <TimelineLogLayerDetails
                    v-for="(childLog, childIndex) in log.children"
                    :key="`log-item-child-${childIndex}`"
                    :title="getLogTitle(childLog)"
                    :time="getLogTime(childLog)"
                    :iot-data="getLogIotData(childLog)"
                    :request-trace="getLogRequestTrace(childLog)"
                    :response-code="getLogResponseCode(childLog)"
                    :variant="`secondary`"
                    :context="getContextData(childLog)"
                  >
                  </TimelineLogLayerDetails>
                </template>
              </TimelineLogItem>
            </template>
          </table>
        </section>
      </div>
    </template>

    <GoogleMapModalAlt
      :marker-is-updatable="false"
      :variant="`vehicle`"
      :title="`Location On The Map`"
      :subtitle="''"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { groupBy, get as propertyValue } from 'lodash-es'
import { getFormattedDateTime, getTimeAgo } from '@/utils/datetime'

import TimelineLogItem from '@/components/timeline/TimelineLogItem.vue'
import TimelineLogLayerVisible from './TimelineLogLayerVisible.vue'
import TimelineLogLayerDetails from './TimelineLogLayerDetails.vue'

import { EventBus } from '@/utils/EventBus'
import { truncate } from '@/plugins/truncate'
import { getIconByAction, getNetworkStrength } from '@/utils'

const HeaderGroups = {
  generic: [
    { label: 'Time', width: '13%' },
    { label: 'Event Type', width: '13%' },
    { label: 'Event Details', width: '74%' },
  ],
  rider: [
    { label: 'Time', width: '10%' },
    { label: 'Event Type', width: '10%' },
    { label: 'Event Details', width: '46%' },
    { label: 'Response', width: '8%', center: true },
    { label: 'Balance', width: '8%', center: true },
    { label: 'Location', width: '8%', center: true },
    { label: 'Trip ID', width: '10%', center: true },
  ],
  trip: [
    { label: 'Time', width: '10%' },
    { label: 'Event Type', width: '10%' },
    { label: 'Event Details', width: '46%' },
    { label: 'Response', width: '8%', center: true },
    { label: 'Battery', width: '10%', center: true },
    { label: 'Location', width: '8%', center: true },
    { label: 'Balance', width: '8%', center: true },
  ],
  vehicle: [
    { label: 'Time', width: '10%' },
    { label: 'Event Type', width: '10%' },
    { label: 'Event Details', width: '46%' },
    { label: 'Response', width: '8%', center: true },
    { label: 'Battery', width: '10%', center: true },
    { label: 'Location', width: '8%', center: true },
    { label: 'Actor', width: '8%', center: true },
  ],
  rental: [
    { label: 'Time', width: '10%' },
    { label: 'Event Type', width: '10%' },
    { label: 'Event Details', width: '40%' },
    { label: 'Status', width: '10%', center: true },
    { label: 'Balance', width: '10%', center: true },
    { label: 'Battery', width: '10%', center: true },
    { label: 'Time Ago', width: '10%', center: true },
  ],
  report: [
    { label: 'Time', width: '10%' },
    { label: 'Event Type', width: '10%' },
    { label: 'Event Details', width: '46%' },
    { label: 'Response', width: '8%', center: true },
    { label: 'Points', width: '8%', center: true },
    { label: 'Remarks', width: '8%', center: true },
    { label: 'Actor', width: '10%', center: true },
  ],
}
const ResponsiveHeaderGroups = [
  { text: '', width: '10%' },
  { label: 'Time', width: '13%' },
  { label: 'Event Details', width: '74%' },
]
export default {
  name: 'TimelineGroup',

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    logs: {
      type: Array,
      default: () => [],
    },
    infoColumnPreset: {
      type: String,
      default: 'default',
    },
    timelineType: {
      type: String,
      required: false,
      default: 'generic',
    },
    isPointsShow: {
      type: Boolean,
      default: true,
    },
  },

  components: {
    TimelineLogItem,
    TimelineLogLayerVisible,
    TimelineLogLayerDetails,
    GoogleMapModalAlt: () =>
      import('@/components/modals/GoogleMapModalAlt.vue'),
  },

  computed: {
    getLogs() {
      return this.mergeSortAndGroupData(this.logs)
    },
    getHeaders() {
      if (!this.isPointsShow) {
        const tHeaders = HeaderGroups[this.timelineType]
        return tHeaders.filter((item) => item.label !== 'Points')
      }
      return HeaderGroups[this.timelineType]
    },
    getResponsiveHeaders() {
      return ResponsiveHeaderGroups
    },
  },

  methods: {
    getPointsHistory(log) {
      return propertyValue(log, 'extra_data.context_data.points')
    },
    getRemarks(log) {
      return propertyValue(log, 'extra_data.context_data.remarks') ?? '--'
    },
    getUserBalance(log) {
      const conversionRate = propertyValue(log, 'conversion_rate')

      let balance = 0.0

      if (log.user_balance) {
        balance = parseFloat(log.user_balance)
      } else {
        const pdBalance = propertyValue(
          log,
          'extra_data.user_data.profile_data.balance'
        )

        if (pdBalance) {
          balance = parseFloat(pdBalance)
        }
      }

      return typeof balance === 'number'
        ? (balance * (conversionRate || 1)).toFixed(2)
        : '--'
    },
    getUserBalanceCurrency(log) {
      const prefCurrency = propertyValue(log, 'preferred_currency.symbol')

      if (prefCurrency) {
        return prefCurrency
      }

      if (log.user_currency_symbol) {
        return log.user_currency_symbol
      }

      const fdCurrency = propertyValue(
        log,
        'extra_data.user_data.profile_data.current_fleet.currency.symbol'
      )

      if (fdCurrency) {
        return fdCurrency
      }

      const orgInfo = this.$store.getters['auth/organizationInfo']
      return orgInfo ? orgInfo.default_currency.symbol : undefined
    },
    toJSONSafe(rawData) {
      try {
        return JSON.parse(rawData)
      } catch {
        return rawData
      }
    },
    mergeSortAndGroupData(data) {
      const parents = data.filter(
        (log) => log.id === log.parent_log_id || log.parent_log_id === null
      )

      const children = data.filter(
        (log) => log.parent_log_id !== null && log.parent_log_id !== log.id
      )

      // sort children by date, latest first
      // console.log('before', children[0].created_at)
      const sortedChildren = children.sort((a, b) => {
        return new Date(a.created_at) - new Date(b.created_at)
      })
      // console.log('after', sortedChildren[0].created_at)

      // merge children with parents
      let merged = parents.map((parent) => {
        const child = sortedChildren.filter(
          (child) => child.parent_log_id === parent.id
        )
        return {
          ...parent,
          children: child,
        }
      })

      console.log(
        'counts (t p c m)',
        data.length,
        parents.length,
        children.length,
        merged.length
      )

      if (merged.length === 0 && data.length > 0) {
        merged = data.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        )
      }

      //  merged.forEach((m) => console.log('m:' + m.children.length + '\n'))

      merged.map((log) => {
        log.groupableDate = dayjs(log.created_at).format('YYYY-MM-DD')
      })

      // sort by created_at, latest first
      const sorted = merged.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at)
      })

      // group by tirp id
      // logs[0] = { type: 'cluster|scatter', data: {} }
      // if tripId !== null, then group by tripId, type is cluster
      // if tripId === null, then group by groupableDate, type is scatter
      const groupedData = sorted.reduce((acc, log) => {
        const key = log.trip
        if (!acc[key]) {
          acc[key] = []
        }
        acc[key].push(log)
        return acc
      }, {})
      console.debug('groupedData', groupedData)

      const grouped = groupBy(sorted, (log) => {
        if (log.trip_id !== null) {
          return log.trip_id
        } else {
          return log.groupableDate
        }
      })
      const groupedByTrip = sorted.reduce((acc, log) => {
        if (log?.trip?.id) {
          acc[log.trip_id] = []
          acc[log.trip.id].push(log)
        }
        return acc
      }, {})

      console.log('groupedByTrip', groupedByTrip)

      // group by date
      // const grouped = sorted.reduce((acc, log) => {
      //   const group = acc.find(
      //     (group) => group.groupableDate === log.groupableDate
      //   )
      //   if (group) {
      //     group.logs.push(log)
      //   } else {
      //     acc.push({
      //       groupableDate: log.groupableDate,
      //       logs: [log],
      //     })
      //   }
      //   return acc
      // }, [])

      console.log(
        'groupsLength',
        grouped.length,
        'sortedLength',
        sorted?.length
      )
      console.log('returned', grouped?.length > 0 ? grouped : [sorted])

      return grouped?.length > 0 ? grouped : [sorted]
    },

    getConnector(log, logIndex, logs = this.logs) {
      // console.log('getConnector', log.trip, logIndex)
      if (!log.trip) {
        console.log('no trip')
        return 'none'
      }

      // console.log('getConnector', log.trip, logIndex, logs.length)

      // if (log.trip) {
      //   const action = log.action_type
      //   const actionUnprefixed = action.replace(/BIKE_|USER_|/g, '')
      //   if (
      //     actionUnprefixed === 'TRIP_STARTED' ||
      //     actionUnprefixed === 'TRIP_RESERVATION'
      //   ) {
      //     return 'after'
      //   } else if (
      //     actionUnprefixed === 'GOOD_FEEDBACK' ||
      //     actionUnprefixed === 'BAD_FEEDBACK'
      //   ) {
      //     return 'before'
      //   } else {
      //     return 'duo'
      //   }
      // }

      const r =
        logIndex.length === 1
          ? 'none'
          : logIndex === 0
          ? 'after'
          : logIndex === logs.length - 1
          ? 'before'
          : 'duo'

      // console.log('r', r, logIndex)

      return r
    },

    getLogIconClass(log) {
      const icons = {
        ok: 'text-oGreen text-sm fa fa-check-circle',
        err: 'text-oRed text-sm fa fa-exclamation-circle',
        unknown: 'text-gray-400 text-sm fa fa-question-circle',
      }
      const statusCode = log?.request_trace?.response_code
      if (!statusCode) return icons.unknown
      if (statusCode >= 200 && statusCode < 300) return icons.ok
      if (statusCode >= 400 && statusCode <= 500) return icons.err
      return icons.unknown
    },

    getLogIconSrc(log) {
      const action = log?.action_type
      return getIconByAction(action)
    },

    onClickMap(coords) {
      console.warn(coords)
      EventBus.$emit('gmap-popup-alt-location', coords)
      this.$modal.show('gmap-popup-alt')
    },

    // log item getters
    getHtmlTitle(log) {
      const actionType = log.action_type || '--'
      const actionTypeText = actionType
        .replace(/USER_|BIKE_/, '')
        .replace('_', ' ')

      return `<b class="font-bold">${actionTypeText}</b> : <span class="font-normal">${log.description ||
        '--'}</span>`
    },
    getLogTitle(log) {
      return log?.description || '--'
    },
    getLogTime(log) {
      return this.$UTCAwareTime(log?.created_at, {
        format: 'hh:mm:ss a',
      })
    },
    getLogDate(log) {
      return log && log.created_at
        ? getFormattedDateTime(log.created_at, 'D MMM, YYYY')
        : '--'
    },
    getLogTimeAgo(log) {
      return log && log.created_at ? getTimeAgo(log.created_at) : '--'
    },
    getLogRequestTrace(log) {
      const trace = log?.request_trace
      if (!trace) return ''

      const reqTitle = '<div>Request Logs</div><div>==============</div>'
      const resTitle =
        '<br/><br/><div>Response Logs</div><div>==============</div>'

      const reqLogs = {
        id: trace?.id,
        ip: trace?.ip,
        user_agent: trace?.user_agent,
        host: trace?.host,
        method: trace?.method,
        path: trace?.path,
        request_body: this.toJSONSafe(trace?.request_body),
      }

      // todo: parse json
      /* let resp
      try {
        resp = JSON.parse(trace, null, 4)
        console.log('resp', resp)
      } catch (error) {
        resp = trace?.response
        console.log('not resp', resp)
      } */

      let response = trace?.response
      const resLogs = {
        language: trace?.language,
        response_code: trace?.response_code,
        response_time: trace?.exec_time,
        response_body: this.toJSONSafe(response),
      }
      return `<span class="block overflow-auto">${reqTitle}${JSON.stringify(
        reqLogs,
        null,
        4
      )}<br/>${resTitle}${JSON.stringify(resLogs, null, 4)}</span>`
    },
    getLogIotData(log) {
      console.log('iot-data', log)
      const reqTitle = '<div>Request Logs</div><div>==============</div>'
      const resTitle =
        '<br/><br/><div>Response Logs</div><div>==============</div>'
      let iot_data = log?.extra_data?.iot_data
      if (iot_data) {
        let parsed_data = iot_data?.parsed_data
        if (typeof parsed_data == 'string') {
          let back_slash = '\\'
          parsed_data = parsed_data.replace(`/${back_slash}/g`, '')
          iot_data.parsed_data = JSON.parse(parsed_data)
        }
        return `${
          iot_data.data_type === 'response' ? resTitle : reqTitle
        }<span class="block overflow-auto">${JSON.stringify(
          iot_data.data,
          null,
          4
        )}</span>`
      }
    },
    getLogResponseCode(log) {
      return log?.request_trace?.response_code || 0
    },
    getActor(log) {
      if (log.target && log.target.role_name) {
        return {
          full_name: log.target.full_name,
          role_name: log.target.role_name,
          id: log.target.id,
        }
      } else if (log.actor && log.actor.role_name) {
        return {
          full_name: log.actor.full_name,
          role_name: log.actor.role_name,
          id: log.actor.id,
        }
      } else if (log.extra_data && log.extra_data.user_data) {
        return {
          full_name: log.extra_data.user_data.profile_data.full_name,
          role_name: log.extra_data.user_data.role,
          id: log.extra_data.user_data.id,
        }
      }

      return null
    },
    getLogItemVariant(log) {
      const code = this.getLogResponseCode(log)
      if (code >= 400 && code <= 500) return 'danger'
      // if (code >= 200 && code < 300) return 'light'
      return 'light'
    },
    getPowerLevel(log) {
      // console.log(log?.extra_data?.vehicle_data?.lock_data, 'poe')
      const p = parseInt(log?.extra_data?.vehicle_data?.lock_data?.power_level)
      return typeof p === 'number' && !isNaN(p) ? p : 0
    },
    getContextData(log) {
      /*
        extra_data: {
            trip_data: {
                id: '717732c4-2fca-4c18-a32b-c4a2b1865cf0',
                status: 'C',
                discount: 0,
                net_cost: 8,
                short_id: 'VgrZDPV',
                pass_data: null,
                promo_data: null,
                has_invoice: true,
                status_forced: true,
                trip_duration: '2 min',
                unlock_charge: 1,
                cancel_reason_desc: 'No reason provided',
                reservation_charge: 0,
                reservation_duration: null,
                status_forced_by_system: false,
            },
            user_data: {
                id: 'cfd16c78-aefa-4715-a7cb-f3337b160f6f',
                role: 'Owner',
                profile_data: {
                email: 'info.riduan@gmail.com',
                balance: 1979,
                full_name: 'Reduanul Islam',
                app_version: null,
                trip_status: 'F',
                user_status: 'A',
                phone_number: '+8801757262754',
                current_fleet: {
                    id: '5b154e50-5e5f-42df-b433-379072f324b0',
                    name: 'OTORide-HQ',
                },
                app_device_model: null,
                last_stored_ip_addrs: 'None',
                },
            },
            context_data: null,
            vehicle_data: {
                id: '95092052-d0de-48fe-9eda-a7f69b7f5d91',
                fleet: {
                id: '5b154e50-5e5f-42df-b433-379072f324b0',
                name: 'OTORide-HQ',
                },
                qr_code: '1011020288',
                is_parked: false,
                lock_data: {
                qr_code: '862506041134928',
                is_locked: false,
                power_level: 73,
                iot_category: 'OMNI_SCOOTER',
                is_operational: true,
                network_signal: 18,
                },
                is_on_ride: false,
                trip_status: 'L',
                is_available: true,
                service_area: {
                id: '03398595-933b-44e4-8931-80de689f83d6',
                name: 'Niketan-HQ',
                },
                current_location: '23.781242,90.4211609',
                position_accuracy: 500,
            },
         },
      */

      const action = log?.action_type
      const actionUnprefixed = action.replace(/BIKE_|USER_/g, '')
      const context = log?.extra_data

      const riderName = context?.user_data?.profile_data?.full_name
      const riderRoute = {
        name: 'ViewRiderUserProfile',
        param: { id: context?.user_data?.id },
      }

      const serviceArea = context?.vehicle_data?.service_area
      const serviceAreaRoute = {
        name: 'ViewServiceArea',
        param: { id: serviceArea?.id },
      }

      const trip = context?.trip_data
      const tripRoute = {
        name: 'ViewTrip',
        param: { id: context?.trip_data?.id },
      }

      const vehicle = context?.vehicle_data
      const vehicleRoute = {
        name: 'ViewVehicleProfile',
        param: { id: vehicle?.id },
      }
      const isOnRide = vehicle?.is_on_ride

      if (action === 'BIKE_RESERVED') {
        return [
          {
            label: 'Rider Name',
            value: riderName,
            route: riderRoute,
          },
          {
            label: 'Vehicle QRCode',
            value: vehicle?.qr_code,
            route: vehicleRoute,
          },
          {
            label: 'Trip ID:',
            value: trip?.id,
            route: tripRoute,
          },
          {
            label: 'Reservation Price',
            //  todo: currency & amount
            value: trip?.reservation_charge,
          },
        ]
      }

      //   if (action === 'BIKE_RESERVATION_CANCELLED') {
      //     return [
      //   {
      //     label: 'TODO',
      //     value: riderName,
      //     route: riderRoute,
      //   },
      //     ]
      //   }

      if (action === 'BIKE_TRIP_STARTED') {
        return [
          {
            label: 'Trip ID',
            value: truncate(trip?.id, -5, '#'),
            badge: false,
            route: tripRoute,
          },
          {
            label: 'Service Area',
            value: serviceArea?.name,
            route: serviceAreaRoute,
            badge: false,
          },
          {
            label: 'Trip Status',
            value: isOnRide ? 'On Rent' : 'Free',
            badge: isOnRide ? 'purple' : 'green',
            route: false,
          },
        ]
      }

      if (action === 'BIKE_TRIP_COMPLETED') {
        return [
          {
            label: 'Vehicle in Service Area',
            value: serviceArea?.name,
            badge: false,
            route: serviceAreaRoute,
          },
          {
            label: 'Lock status',
            value: vehicle?.lock_data?.is_locked ? 'Locked' : 'Unlocked',
            badge: false,
            route: false,
          },
          {
            label: 'Power level',
            value:
              Number(vehicle?.lock_data?.power_level).toFixed() + '%' || '0%',
            badge: false,
            route: false,
          },
        ]
      }

      if (
        action === 'BIKE_LOCK_REQUEST' ||
        action === 'BIKE_LOCKED' ||
        action === 'BIKE_UNLOCK_REQUEST' ||
        action === 'BIKE_UNLOCKED' ||
        action === 'BIKE_CABLE_LOCK_REQUEST' ||
        action === 'BIKE_CABLE_LOCKED' ||
        action === 'BIKE_CABLE_UNLOCK_REQUEST' ||
        action === 'BIKE_CABLE_UNLOCKED'
      ) {
        const isCable = action.substring(5, 10) === 'CABLE'
        const lockStatus = isCable ? 'Cable lock' : 'Lock'
        const data = [
          {
            label: `${lockStatus} status`,
            value: vehicle?.is_locked?.is_locked ? 'Locked' : 'Unlocked',
            badge: false,
            route: false,
          },
          {
            label: 'Availability status',
            value: vehicle?.is_available ? 'Available' : 'Unavailable',
            badge: false,
            route: false,
          },
          {
            label: 'Power level',
            value:
              Number(vehicle?.lock_data?.power_level).toFixed() + '%' || '0%',
            badge: false,
            route: false,
          },
          {
            label: 'Network signal',
            value: getNetworkStrength(vehicle?.lock_data?.network_signal),
            badge: false,
            route: false,
          },
          {
            label: 'Location accuracy',
            value: vehicle?.position_accuracy || 0,
            badge: false,
            route: false,
          },
        ]

        if (
          action === 'BIKE_LOCKED' ||
          action === 'BIKE_CABLE_LOCKED' ||
          action === 'BIKE_UNLOCKED' ||
          action === 'BIKE_CABLE_UNLOCKED'
        ) {
          data.unshift({
            label: 'Service Area',
            value: serviceArea?.name,
            badge: false,
            route: serviceAreaRoute,
          })
        }

        return data
      }
      //

      const badFeedback = 'BAD_FEEDBACK'
      const goodFeedback = 'GOOD_FEEDBACK'
      if (
        actionUnprefixed === badFeedback ||
        actionUnprefixed === goodFeedback
      ) {
        return [
          {
            label: 'Rating',
            value: actionUnprefixed === goodFeedback ? '👍 Good' : '👎 Bad',
            badge: false,
            route: false,
          },
        ]
      }

      return [
        {
          label: 'Context Data',
          value: `N/A [${action}]`,
          badge: false,
          route: false,
        },
      ]
    },
  },
}
</script>

<style lang="scss" scoped>
.timeline-section {
  min-height: 4rem;
  @apply grid items-center w-full px-6 py-1 mb-2 bg-white rounded-md shadow;
  @apply grid-cols-1 md:grid-cols-3;
}
.timeline-title {
  display: grid;
  // border: 1px solid red;
  grid-template-columns: 175px 1fr;

  @media screen and (min-width: 768px) {
    grid-template-columns: 175px 1fr 375px;
  }
}

.fst .fst-head,
.fst .fst-head .fst-head-row {
  border-color: transparent !important;
  box-shadow: none !important;
}

.event-col-header {
  font-weight: 800 !important;
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
}

.fst-wrapper {
  @apply p-4;
}

.ta-center-imp {
  text-align: center !important;
}
</style>

<style lang="scss" scoped>
@import '@/components/fs-table/$fs-table.scss';
</style>
